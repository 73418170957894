export default function Hexagon({xLocation, yLocation, boardStateCell, handleChoice }) {

  const handleClick = () => {
    handleChoice(boardStateCell.x, boardStateCell.y);
  }

  const fillColor = () => {
    if (boardStateCell.blocked)
      return "#d6e8f5"; // white
    return "#8ab9f1";   // blue
  }

  const cellImage = () => {
    if (boardStateCell.occupied) {
      return (
        // https://www.svgrepo.com/svg/243295/sailing-boat-boat
        <image x="-7" y="-12" width="16" height="16" href="img/ship.svg" />
      );
    }
    if (boardStateCell.blocked) {
      
      if (boardStateCell.randomNumber === 1) {
        // https://www.svgrepo.com/svg/65827/polar-bear
        return ( <image x="-4" y="-7" width="9" height="9" href="img/polar-bear.svg" /> );
      }

      if (boardStateCell.randomNumber === 2) {
        // https://www.svgrepo.com/svg/73652/penguin
        return ( <image x="-5" y="-7" width="9" height="9" href="img/penguin.svg" /> );
      }

      if (boardStateCell.randomNumber === 3) {
        // https://www.svgrepo.com/svg/42305/seal
        return ( <image x="-4" y="-7" width="9" height="9" href="img/seal.svg" /> );
      }
      
    }
  }

  // reference: https://codepen.io/SanderMoolin/pen/BRLvNb
  return (
    <g className="hexagon-group" onClick={e => handleClick()}>
      <g className="hexagon" transform={"translate(" + xLocation + ", " + yLocation + ")"}>
        <g className="tile-bottom">
          <polygon
            stroke="#000000"
            fill={ fillColor() }
            strokeWidth="0.5" 
            points="0,-10 -9,-5 -9,5 0,10 9,5 9,-5"   // pointy top hexagon
          />
          <polyline
            stroke="#000000"
            strokeWidth="0.5"
            fill="#000000"
            points="0,10 0,7"
          />
        </g>
        <g className="tile-top">
          <polygon 
            stroke="#000000"
            fill={ fillColor() }
            strokeWidth="0.5" 
            points="0,-10 -9,-5 -9,2 0,7 9,2 9,-5"   // pointy top hexagon
          />
          { cellImage() }
        </g>

        {/* <polygon 
          stroke="#000000"
          fill={ fillColor() }
          strokeWidth="0.5" 
          points="0,-10 -9,-5 -9,5 0,10 9,5 9,-5"   // pointy top hexagon
        /> */}
      </g>
    </g>
  )
}