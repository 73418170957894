import "./Modal.css";

export default function Modal({winner, setupBoard}) {

  if (winner === "bot") {
    return (
      <div className="modalContainer">
        <div className="modal" id="modal">
          <h2><img src="img/ship.svg" width="50" height="50" alt="Ship Icon" /> The Ship Escaped!</h2>
          <div className="content">You must trap it to win.</div>
          <div className="actions">
            <button className="toggle-button" onClick={setupBoard}>New Game</button>
          </div>
        </div>
      </div>
    );
  } 

  if (winner === "human") {
    return (
      <div className="modalContainer">
        <div className="modal" id="modal">
          <h2><img src="img/penguin.svg" width="50" height="50" alt="Penguin Icon"/> Winner!!</h2>
          <div className="content">You trapped the ship.</div>
          <div className="actions">
            <button className="toggle-button" onClick={setupBoard}>New Game</button>
          </div>
        </div>
      </div>
    );
  } 

  return;
};