import { useEffect, useState } from "react";
import { dimensions, defaultBlockedCells } from "./Config";
import { getShortestPath, getOpenNeighborCells } from "./PathFinder";
import HexGrid from "./components/HexGrid";
import Modal from "./components/Modal";
import "./App.css";

function App() {
  const [boardState, setBoardState] = useState(null);
  const [edgesList, setEdgesList] = useState([]);
  const [botCell, setBotCell] = useState(null);
  const [botsTurn, setBotsTurn] = useState(false);
  const [winner, setWinner] = useState(false);
  const [turns, setTurns] = useState(0);

  var CellState = function (x, y, occupied, blocked) {
    return {
      x: x,
      y: y,
      occupied: occupied,
      blocked: blocked,
      randomNumber: Math.floor(Math.random() * 30) + 1
    };
  };

  // new game
  const setupBoard = () => {
    // default occupied to center
    var occupiedX = Math.ceil(dimensions.width / 2.0);
    var occupiedY = Math.ceil(dimensions.height / 2.0);

    // generate total # of blocked (min 5, max 11)
    const blockedCells = generateBlockedCells(defaultBlockedCells.min, defaultBlockedCells.max);

    // set overall board state
    var startBoardState = [];
    var newEdgesList = [];
    var cellNumber = 0;
    for (var x = 0; x <= dimensions.width + 1; x++) {
      startBoardState[x] = [];
      for (var y = 0; y <= dimensions.height + 1; y++) {
        var occupied = x === occupiedX && y === occupiedY;
        var edge = isEdge(x, y);
        var blocked = (!occupied && blockedCells.includes(cellNumber));
        startBoardState[x][y] = CellState(x, y, occupied, blocked);
        if (edge) newEdgesList.push(startBoardState[x][y]);
        cellNumber = cellNumber + 1;
      }
    }

    setBoardState(startBoardState);
    setEdgesList(newEdgesList);
    setBotCell(startBoardState[occupiedY][occupiedY]);
    setBotsTurn(false);
    setWinner(false);
    setTurns(0);
  };

  const isEdge = (x, y) => {
    return  (x===0 || y===0 || x===(dimensions.width+1) || y===(dimensions.height+1))
  }

  const generateBlockedCells = (minBlocked, maxBlocked) => {
    // skip edges
    const allCellsArray = [];
    var cellNumber = 0;
    for(var x = 0; x <= dimensions.width+1; x++) {
      for (var y = 0; y <= dimensions.height+1; y++) {
        cellNumber = cellNumber + 1;
        if (!isEdge(x,y)) allCellsArray.push(cellNumber);
      }
    }

    const totalBlockedCells = Math.floor(Math.random() * (maxBlocked - minBlocked + 1) + minBlocked);
    const shuffledCellsArray = allCellsArray.sort(() => Math.random() - 0.5).sort(() => Math.random() - 0.5);
    const blockedCellsArray = shuffledCellsArray.slice(0, totalBlockedCells);
    return blockedCellsArray;
  };


  // Player's turn
  const handleChoice = (columnNumber, rowNumber) => {
    if (!winner) {
      var boardStateCopy = [...boardState];
      if (! boardStateCopy[columnNumber][rowNumber].occupied && ! boardStateCopy[columnNumber][rowNumber].blocked) {
        boardStateCopy[columnNumber][rowNumber].blocked = true;
        setBoardState(boardStateCopy);
        setBotsTurn(true);
        setTurns((prevTurns) => prevTurns + 1);
      }
    }
  };


  // Bot's turn
  useEffect(() => {
    if (botsTurn) {
      var boardStateCopy = [...boardState];
      
      // reset the secretPath
      boardStateCopy.forEach(row => {
        row.forEach(cell => {
          cell.secretPath = false;
        })
      })

      // get paths to exit
      var pathToGoal = getShortestPath(botCell, edgesList, boardState);
      var nextMove;

      // Bot is trapped! Move to next open space
      if (pathToGoal === undefined && ! isEdge(botCell.x, botCell.y)) {
        console.log("BOT is TRAPPED");
        var openNeighborCells = getOpenNeighborCells(botCell, boardState);
        if (openNeighborCells !== undefined && openNeighborCells.length > 0) {
          openNeighborCells.sort(() => Math.random() - 0.5);
          nextMove = openNeighborCells.pop();
          boardStateCopy[botCell.x][botCell.y].occupied = false;
          boardStateCopy[nextMove.x][nextMove.y].occupied = true;
        } else {
          console.log("WINNER WINNER CHICKEN DINNER");
          setWinner("human");
        }
      }

      // Move Bot to exit
      if (pathToGoal !== undefined && pathToGoal.length >= 1 && ! isEdge(botCell.x, botCell.y)) {
        nextMove = pathToGoal[pathToGoal.length - 1]; // get next cell in path to exit
        boardStateCopy[botCell.x][botCell.y].occupied = false;
        boardStateCopy[nextMove.x][nextMove.y].occupied = true;

        // check if bot won
        if (isEdge(nextMove.x, nextMove.y)) {
          console.log("BOT WON! GAME OVER");
          setWinner("bot");
        }

        // set secretPath
        pathToGoal.forEach(nextMove => {
          boardStateCopy[nextMove.x][nextMove.y].secretPath = true;
        })
      }

      setBotCell(nextMove);
      setBoardState(boardStateCopy);
      setBotsTurn(false);
    }
  }, [botsTurn]);

  // start a new game automatically
  useEffect(() => {
    setupBoard();
  }, []);


  return (
    <div className="App">
      <h1>Catch the Ship</h1>
      <button onClick={setupBoard}>New Game</button>
      <Modal winner={winner} setupBoard={setupBoard} />
      <HexGrid boardState={boardState} handleChoice={handleChoice} />
      <p>Turns: {turns}</p>
    </div>
  );
}

export default App;