import { dimensions } from "./Config";

const getShortestPath = (botCell, edgesList, boardState) => {
  var shortestPathDistance = 1000000;
  var shortestPathMap = new Map();

  edgesList.forEach(edgeCell => {
    var pathtoEdge = findPathToEdge(botCell, edgeCell, boardState);
    // Save the shortest path
    if (pathtoEdge.length > 0 && pathtoEdge.length < shortestPathDistance) {
      shortestPathDistance = pathtoEdge.length;
      shortestPathMap.set(shortestPathDistance, pathtoEdge);
    }
  })

  // fetch the shortest
  var pathLengths = Array.from( shortestPathMap.keys() );
  pathLengths.sort(function(a, b){return a-b});
  return shortestPathMap.get(pathLengths[0]);
}


const findPathToEdge = (botCell, edgeCell, boardState) => {
  // frontier search
  var frontier = [botCell];
  const cameFrom = new Map();
  cameFrom.set(botCell, null);

  // frontier search
  while(frontier.length > 0) {
    var currentCell = frontier[0];

    // walk the neighbors
    getOpenNeighborCells(currentCell, boardState).forEach(openNeighborCell => {
      if (! cameFrom.has(openNeighborCell)) {
        frontier.push(openNeighborCell);
        cameFrom.set(openNeighborCell, currentCell);
      }
    })
    frontier.shift();
  }

  // path finder
  var path = [];
  try {
    var currentEdgeCell = edgeCell;
    while (currentEdgeCell.x !== botCell.x || currentEdgeCell.y !== botCell.y) {
      path.push(currentEdgeCell);
      currentEdgeCell = cameFrom.get(currentEdgeCell);
    }
  } catch (error) {
    //console.log("WARNING: An edge node is unreachable but still open.");
    //console.log("botCell", botCell, "edgeCell", edgeCell);
    //console.log("cameFrom: ", cameFrom);
    path = [];
  }

  return path;
}

const getOpenNeighborCells = (currentCell, boardState) => {
  const EVEN_ROW_NEIGHBORS = [[+1, 0], [+1, +1], [0, +1], [-1, 0], [0, -1], [+1, -1]];
  const ODD_ROW_NEIGHBORS = [[+1, 0], [0, +1], [-1, +1], [-1, 0], [-1, -1], [0, -1]];

  var openNeighborCells = [];
  var neighborFormula = (currentCell.y % 2 === 0) ? EVEN_ROW_NEIGHBORS : ODD_ROW_NEIGHBORS;

  neighborFormula.forEach(pair => {
    var neighborX = pair[0] + currentCell.x;
    var neighborY = pair[1] + currentCell.y;
    if (isValidCell(neighborX, neighborY) && ! boardState[neighborX][neighborY].blocked) {
      openNeighborCells.push(boardState[neighborX][neighborY]);
    }
  })
  return openNeighborCells;
}

const isValidCell = (x, y) => {
  return (x >= 0 && x <= dimensions.width+1 && y >= 0 && y <= dimensions.height+1 )
}

export {getShortestPath, getOpenNeighborCells};