const dimensions = {
  height: 11,
  width: 11,
};

const defaultBlockedCells = {
  min: 5,
  max: 11
}

export { dimensions, defaultBlockedCells };