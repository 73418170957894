import { dimensions } from "../Config";
import Hexagon from "./Hexagon";

export default function HexGrid({boardState, handleChoice}) {

  const boardHexagons = () => {
    if (boardState === undefined || boardState === null) return;
    var boardHexagons = [];
    var yLocation = 0;
    for (var row = 1; row <= dimensions.height; row++) {
      yLocation = yLocation + 12;
      var xLocation = (row % 2 === 0) ? 9 : 0;

      for (var column = 1; column <= dimensions.width; column++) {
        xLocation = xLocation + 18;
        boardHexagons.push(
          <Hexagon
            xLocation={xLocation}
            yLocation={yLocation}
            boardStateCell={boardState[column][row]}
            handleChoice={handleChoice}
          />
        );
      }
    }
    return boardHexagons;
  }

  // draw the board Hexagons
    return (
      <svg viewBox="0 0 220 145">
        {boardHexagons()}
      </svg>
    );

  };